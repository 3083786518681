// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


/* Set variables for all modes */
:root {
  /* Set the font family of the entire app */
  --ion-font-family: "Montserrat", sans-serif;
  --font-secondary: "Caveat";
  --transition: all .25s ease;
  --border-color: #D6D6D9;
  --color-additional: #525456;

  /*grid*/
  --ion-grid-padding:               7px;

  --ion-grid-padding-xs:            7px;
  --ion-grid-padding-sm:            7px;
  --ion-grid-padding-md:            7px;
  --ion-grid-padding-lg:            7px;
  --ion-grid-padding-xl:            7px;

  --ion-grid-column-padding:        7px;

  --ion-grid-column-padding-xs:     7px;
  --ion-grid-column-padding-sm:     7px;
  --ion-grid-column-padding-md:     7px;
  --ion-grid-column-padding-lg:     7px;
  --ion-grid-column-padding-xl:     7px;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #921b1b;;
	--ion-color-primary-rgb: 146,27,27;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #801818;
	--ion-color-primary-tint: #9d3232;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61,194,255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #F5F5F5;
  --ion-color-tertiary-rgb: 245,245,245;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #d8d8d8;
  --ion-color-tertiary-tint: #f6f6f6;

  /** success **/
  --ion-color-success: #3CAE4A;
  --ion-color-success-rgb: 60,174,74;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #359941;
  --ion-color-success-tint: #50b65c;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #921b1b;;
	--ion-color-danger-rgb: 146,27,27;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #801818;
	--ion-color-danger-tint: #9d3232;

  /** dark **/
  --ion-color-dark: #22262A;
  --ion-color-dark-rgb: 34,38,42;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2125;
  --ion-color-dark-tint: #383c3f;

  /** medium **/
  --ion-color-medium: #98989D;
  --ion-color-medium-rgb: 152,152,157;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86868a;
  --ion-color-medium-tint: #a2a2a7;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255,255,255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
  --ion-color-light-grey: #f2f2f2;
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #921b1b;;
//     --ion-color-primary-rgb: 146,27,27;
//     --ion-color-primary-contrast: #ffffff;
//     --ion-color-primary-contrast-rgb: 255,255,255;
//     --ion-color-primary-shade: #801818;
//     --ion-color-primary-tint: #9d3232;

//     /** secondary **/
//     --ion-color-secondary: #3dc2ff;
//     --ion-color-secondary-rgb: 61,194,255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #36abe0;
//     --ion-color-secondary-tint: #50c8ff;

//     /** tertiary **/
//     --ion-color-tertiary: #F5F5F5;
//     --ion-color-tertiary-rgb: 245,245,245;
//     --ion-color-tertiary-contrast: #000000;
//     --ion-color-tertiary-contrast-rgb: 0,0,0;
//     --ion-color-tertiary-shade: #d8d8d8;
//     --ion-color-tertiary-tint: #f6f6f6;

//     /** success **/
//     --ion-color-success: #3CAE4A;
//     --ion-color-success-rgb: 60,174,74;
//     --ion-color-success-contrast: #ffffff;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #359941;
//     --ion-color-success-tint: #50b65c;

//     /** warning **/
//     --ion-color-warning: #ffc409;
//     --ion-color-warning-rgb: 255,196,9;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0ac08;
//     --ion-color-warning-tint: #ffca22;

//     /** danger **/
//     --ion-color-danger: #921b1b;;
//     --ion-color-danger-rgb: 146,27,27;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #801818;
//     --ion-color-danger-tint: #9d3232;

//     /** dark **/
//     --ion-color-dark: #22262A;
//     --ion-color-dark-rgb: 34,38,42;
//     --ion-color-dark-contrast: #ffffff;
//     --ion-color-dark-contrast-rgb: 255,255,255;
//     --ion-color-dark-shade: #1e2125;
//     --ion-color-dark-tint: #383c3f;

//     /** medium **/
//     --ion-color-medium: #98989D;
//     --ion-color-medium-rgb: 152,152,157;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #86868a;
//     --ion-color-medium-tint: #a2a2a7;

//     /** light **/
//     --ion-color-light: #ffffff;
//     --ion-color-light-rgb: 255,255,255;
//     --ion-color-light-contrast: #000000;
//     --ion-color-light-contrast-rgb: 0,0,0;
//     --ion-color-light-shade: #e0e0e0;
//     --ion-color-light-tint: #ffffff;
//     --ion-color-light-grey: #f2f2f2;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   //.ios body {
//   //  --ion-background-color: #000000;
//   //  --ion-background-color-rgb: 0,0,0;
//   //
//   //  //--ion-text-color: #ffffff;
//   //  //--ion-text-color-rgb: 255,255,255;
//   //
//   //  --ion-color-step-50: #0d0d0d;
//   //  --ion-color-step-100: #1a1a1a;
//   //  --ion-color-step-150: #262626;
//   //  --ion-color-step-200: #333333;
//   //  --ion-color-step-250: #404040;
//   //  --ion-color-step-300: #4d4d4d;
//   //  --ion-color-step-350: #595959;
//   //  --ion-color-step-400: #666666;
//   //  --ion-color-step-450: #737373;
//   //  --ion-color-step-500: #808080;
//   //  --ion-color-step-550: #8c8c8c;
//   //  --ion-color-step-600: #999999;
//   //  --ion-color-step-650: #a6a6a6;
//   //  --ion-color-step-700: #b3b3b3;
//   //  --ion-color-step-750: #bfbfbf;
//   //  --ion-color-step-800: #cccccc;
//   //  --ion-color-step-850: #d9d9d9;
//   //  --ion-color-step-900: #e6e6e6;
//   //  --ion-color-step-950: #f2f2f2;
//   //
//   //  --ion-toolbar-background: #0d0d0d;
//   //
//   //  --ion-item-background: #1c1c1c;
//   //  --ion-item-background-activated: #313131;
//   //}


//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   //.md body {
//   //  --ion-background-color: #121212;
//   //  --ion-background-color-rgb: 18,18,18;
//   //
//   //  --ion-text-color: #ffffff;
//   //  --ion-text-color-rgb: 255,255,255;
//   //
//   //  --ion-border-color: #222222;
//   //
//   //  --ion-color-step-50: #1e1e1e;
//   //  --ion-color-step-100: #2a2a2a;
//   //  --ion-color-step-150: #363636;
//   //  --ion-color-step-200: #414141;
//   //  --ion-color-step-250: #4d4d4d;
//   //  --ion-color-step-300: #595959;
//   //  --ion-color-step-350: #656565;
//   //  --ion-color-step-400: #717171;
//   //  --ion-color-step-450: #7d7d7d;
//   //  --ion-color-step-500: #898989;
//   //  --ion-color-step-550: #949494;
//   //  --ion-color-step-600: #a0a0a0;
//   //  --ion-color-step-650: #acacac;
//   //  --ion-color-step-700: #b8b8b8;
//   //  --ion-color-step-750: #c4c4c4;
//   //  --ion-color-step-800: #d0d0d0;
//   //  --ion-color-step-850: #dbdbdb;
//   //  --ion-color-step-900: #e7e7e7;
//   //  --ion-color-step-950: #f3f3f3;
//   //
//   //  --ion-item-background: #1A1B1E;
//   //}

//   ion-title.title-large {
//     --color: white;
//   }
// }
