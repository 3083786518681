/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
//@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/***********************************************************
                        FONTS
***********************************************************/
//@import url("https://fonts.googleapis.com/css?family=Caveat:700|Montserrat:300,400,500,600,700&display=swap&subset=cyrillic");

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/subset-Montserrat-Light.woff2") format("woff2"),
    url("assets/fonts/subset-Montserrat-Light.woff") format("woff"),
    url("assets/fonts/subset-Montserrat-Light.ttf") format("truetype"),
    url("assets/fonts/subset-Montserrat-Light.svg#Montserrat") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/subset-Montserrat-LightItalic.woff2") format("woff2"),
    url("assets/fonts/subset-Montserrat-LightItalic.woff") format("woff"),
    url("assets/fonts/subset-Montserrat-LightItalic.ttf") format("truetype"),
    url("assets/fonts/subset-Montserrat-LightItalic.svg#Montserrat") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/subset-Montserrat-Regular.woff2") format("woff2"),
    url("assets/fonts/subset-Montserrat-Regular.woff") format("woff"),
    url("assets/fonts/subset-Montserrat-Regular.ttf") format("truetype"),
    url("assets/fonts/subset-Montserrat-Regular.svg#Montserrat") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/subset-Montserrat-Italic.woff2") format("woff2"),
    url("assets/fonts/subset-Montserrat-Italic.woff") format("woff"),
    url("assets/fonts/subset-Montserrat-Italic.ttf") format("truetype"),
    url("assets/fonts/subset-Montserrat-Italic.svg#Montserrat") format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/subset-Montserrat-Medium.woff2") format("woff2"),
    url("assets/fonts/subset-Montserrat-Medium.woff") format("woff"),
    url("assets/fonts/subset-Montserrat-Medium.ttf") format("truetype"),
    url("assets/fonts/subset-Montserrat-Medium.svg#Montserrat") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/subset-Montserrat-MediumItalic.woff2") format("woff2"),
    url("assets/fonts/subset-Montserrat-MediumItalic.woff") format("woff"),
    url("assets/fonts/subset-Montserrat-MediumItalic.ttf") format("truetype"),
    url("assets/fonts/subset-Montserrat-MediumItalic.svg#Montserrat") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/subset-Montserrat-SemiBold.woff2") format("woff2"),
    url("assets/fonts/subset-Montserrat-SemiBold.woff") format("woff"),
    url("assets/fonts/subset-Montserrat-SemiBold.ttf") format("truetype"),
    url("assets/fonts/subset-Montserrat-SemiBold.svg#Montserrat") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/subset-Montserrat-SemiBoldItalic.woff2") format("woff2"),
    url("assets/fonts/subset-Montserrat-SemiBoldItalic.woff") format("woff"),
    url("assets/fonts/subset-Montserrat-SemiBoldItalic.ttf") format("truetype"),
    url("assets/fonts/subset-Montserrat-SemiBoldItalic.svg#Montserrat") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/subset-Montserrat-Bold.woff2") format("woff2"),
    url("assets/fonts/subset-Montserrat-Bold.woff") format("woff"),
    url("assets/fonts/subset-Montserrat-Bold.ttf") format("truetype"),
    url("assets/fonts/subset-Montserrat-Bold.svg#Montserrat") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/subset-Montserrat-BoldItalic.woff2") format("woff2"),
    url("assets/fonts/subset-Montserrat-BoldItalic.woff") format("woff"),
    url("assets/fonts/subset-Montserrat-BoldItalic.ttf") format("truetype"),
    url("assets/fonts/subset-Montserrat-BoldItalic.svg#Montserrat") format("svg");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Caveat";
  src: url("assets/fonts/subset-Caveat-Regular.woff2") format("woff2"),
    url("assets/fonts/subset-Caveat-Regular.woff") format("woff"),
    url("assets/fonts/subset-Caveat-Regular.ttf") format("truetype"),
    url("assets/fonts/subset-Caveat-Regular.svg#Caveat") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Caveat";
  src: url("assets/fonts/subset-Caveat-Bold.woff2") format("woff2"),
    url("assets/fonts/subset-Caveat-Bold.woff") format("woff"),
    url("assets/fonts/subset-Caveat-Bold.ttf") format("truetype"),
    url("assets/fonts/subset-Caveat-Bold.svg#Caveat") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/***********************************************************
                        General
***********************************************************/

app-events {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  pointer-events: none;
}

.container {
  display: grid;
  align-items: center;
}

ion-input {
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

ion-label {
  margin-top: 5px;
}

ion-button {
  --box-shadow: none;

  &:focus {
    outline: none;
  }
}

.full-height {
  height: 100%;
}

.flex {
  display: flex;
  &-start {
    justify-content: start;
  }
  &.between {
    justify-content: space-between;
    flex-flow: column;
  }
}

.bx-xs-20 {
  margin: 20px;
}

.mg-5 {
  margin: 5px;
}

.horizontal-centered {
  justify-content: center;
  margin: auto;
}

img {
  outline: none;
}

.fw-700 {
  font-weight: 700;
}

/***********************************************************
                        POPUPS
***********************************************************/
.title {
  font-weight: bold;
  color: var(--ion-color-dark);
}

.auth-container {
  display: grid;
  align-self: center;
  padding: 0 15px 35px;
  max-width: 600px;
  justify-self: center;
  width: 100%;
}

// .sc-ion-buttons-md-s {
//   font-size: 12px !important;
// }

.auth-form {
  display: grid;
  margin-top: 15px;
}

.form {
  margin-top: 15px;

  ion-item {
    --padding-start: 0px;
  }
}

.auth-form ion-item {
  --padding-start: 0px;
}

.link {
  color: var(--ion-color-primary);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;

  &.ripple-parent {
    padding-left: 5px;
    padding-right: 5px;
  }
}

/***********************************************************
                        HOME PAGE
***********************************************************/

ion-footer ion-toolbar {
  --min-height: 56px;
}

ion-text {
  word-break: break-word !important;
  white-space: initial !important;
}

/***********************************************************
                          ORDER
***********************************************************/

.filter-buttons-wrap {
  white-space: nowrap;
  overflow: auto;
  padding-bottom: 15px;
  &-no-pad {
    padding-bottom: 0;
  }

  ion-button {
    --border-radius: 30px;

    font-size: 13px;
    text-transform: none;
    --box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  }
}

/***********************************************************
                        CATALOGUE
***********************************************************/
.popover-content.sc-ion-popover-ios {
  min-width: 300px !important;
}

.popover-content.sc-ion-popover-md {
  min-width: 300px !important;
}

.ttlover {
  text-transform: lowercase;
}

.tutorial-slider {
  --height: 100%;
  --max-height: calc(100% - 30px);
  --min-width: calc(100% - 30px);
  .popover-content.sc-ion-popover-md {
    top: 15px!important;
    border-radius: 10px;
    min-width: calc(100% - 30px) !important;
  }
}

/***********************************************************
                           SPINNER WITH LAYER
***********************************************************/
ion-spinner {
  width: 28px;
  height: 28px;
  stroke: #444;
  fill: #222;
}

/***********************************************************
                      RESET
************************************************************/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

/***********************************************************
                      Typography
************************************************************/
.text-xxs {
  font-size: 12px;
  line-height: 1.2;
}

.text-xs {
  font-size: 13px;
  line-height: 1.2;
}

.text-sm {
  font-size: 15px;
  &-detail {
    font-family: var(--ion-font-family);
    font-size: 13px;
  }
}

.f-400 {
  font-weight: 400;
}

.f-500 {
  font-weight: 500;
}

h1,
.h1 {
  font-size: 30px;
  font-family: var(--font-secondary);
  line-height: 1.25;
}

h2,
.h2 {
  font-size: 26px;
  font-family: var(--font-secondary);
  line-height: 1.25;
}

h4,
.h4 {
  font-size: 19px;
  line-height: 1.2;
  font-weight: 600;
}

h5,
.h5 {
  font-size: 17px;
  line-height: 1.2;
}

h6,
.h6 {
  font-size: 15px;
}

.top-title {
  margin: 0 0 20px;
}

.decor-title {
  position: relative;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    width: 100%;
    background-image: url(assets/img/decor-line.svg);
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
  }

  .h1 {
    position: relative;
  }
}

/* colors */
.color-dark {
  color: var(--ion-color-dark);
}

.color-medium {
  color: var(--ion-color-medium);
}

.color-primary {
  color: var(--ion-color-primary);
}

.color-additional {
  color: var(--color-additional);
}

/***********************************************************
                        ARTICLE
***********************************************************/
.article {
  * {
    margin: 0;
  }

  > * {
    margin-bottom: 10px;
  }

  > h1,
  h2,
  h3 {
    font-family: var(--font-secondary);

    &:not(:first-child) {
      margin-top: 15px;
    }
  }
  // ion-img
  img {
    border-radius: 5px;
    overflow: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    color: var(--ion-color-dark);
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;

      &:before {
        content: "";
        display: inline-block;
        margin-right: 5px;
        width: 12px;
        height: 1px;
        background: currentColor;
        vertical-align: middle;
      }

      &.single-item {
        &:before {
          content: none;
        }
      }
    }
  }
}

.vacancy-article {
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      position: relative;
      padding-left: 15px;
      &::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid var(--ion-color-primary);
        position: absolute;
        top: 6px;
        left: 0;
      }

      &::after {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: var(--ion-color-primary);
        border: none;
        position: absolute;
        top: 9px;
        left: 3px;
      }
    }
  }
}

/***********************************************************
                      ELEMENTS
************************************************************/
.content-margin {
  // temporary !!!!
  margin-top: 50px;
  // margin-top: 15px;
  margin-bottom: 15px;

  &-2 {
    margin-bottom: 30px;
  }

  &-3 {
    margin-top: 20px;
    margin-bottom: 25px;
  }
}

/*main grid*/
.grid-container {
  padding-inline-start: 10px;
  padding-inline-end: 10px;

  &-no-left {
    padding-inline-start: 0;
  }
  ion-col {
    padding-bottom: 0;
    padding-top: 0;
  }
}

ion-grid {
  padding-top: 0;
  padding-bottom: 0;
}

ion-col {
  padding-bottom: 0;
  padding-top: 0;
}
/*end of main grid*/

/* buttons */
.btn,
.sc-ion-buttons-ios-s .btn {
  --padding-start: 15px;
  --padding-end: 15px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --border-radius: 50px !important;
  --box-shadow: none;
  outline: none;

  font-size: 11px;
  text-transform: uppercase;
  margin: 0;
  height: 50px;

  &.button-disabled {
    opacity: 1;
    --ion-color-base: #d7d7d7 !important;
  }

  &.btn-sm {
    height: 40px;
  }

  &.btn-min-width {
    min-width: 170px;
  }

  &.btn-secondary {
    text-transform: none;
    font-weight: 500;
    font-size: 15px;

    ion-icon {
      font-size: 2em;
    }
  }
}
/* end of buttons */

/* segment */
.custom-segment {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 1px;
    height: 1px;
    width: 100%;
    background: var(--ion-color-medium);
    opacity: 0.2;
  }

  ion-segment-button {
    --indicator-box-shadow: inset 10px 10px 10px 10px var(--ion-color-primary);
    font-size: 13px;
    font-weight: 500;
    min-height: auto;
    color: var(--ion-color-dark);

    &.segment-button-checked {
      font-weight: 700;
    }

    &:focus {
      outline: none;
    }

    ion-label {
      margin-bottom: 8px;
      line-height: 1.5;
      letter-spacing: normal;
    }
  }
}
/* end of segment */
/* styled list */
.styled-list {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  > li {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .btn-to-favourite {
      position: relative;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
    }

    .list-item-content {
      align-self: center;
    }
  }

  &-2 {
    > li:first-child {
      border-top: 0;
    }
  }
}

/* end of styled list */
/***********************************************************
                        AUTH
***********************************************************/
.pattern-bg {
  --background: url(assets/img/patern.png) center center no-repeat #fff;
}

.header-transparent {
  ion-back-button {
    --padding-bottom: 10px;
    --padding-end: 10px;
    --padding-start: 10px;
    --padding-top: 10px;
  }
}

/* form elements */
.form-item {
  --color: var(--color-additional);
  --min-height: 40px;
  --background: transparent;
  --padding-start: 0;

  font-weight: 500;
  font-size: 15px;
  padding-top: 10px;

  ion-input {
    text-overflow: ellipsis;
  }

  ion-input,
  ion-textarea {
    --padding-start: 10px !important;
    --background: transparent;
    font-weight: 600;
    color: var(--ion-color-dark);

    --placeholder-color: #525456;
    --placeholder-font-weight: 500;
    --placeholder-opacity: 1;
  }

  .form-item-label {
    margin-top: 0;
    padding-left: 10px;
    transform: translate3d(0, 20px, 0);
  }

  &.checkbox-item {
    --inner-border-width: 0;
    --color: var(--ion-color-dark);

    ion-checkbox {
      --border-radius: 4px;
      --border-color: var(--ion-color-medium);
      opacity: 0.5;
      --border-color-checked: var(--ion-color-primary);
      --background-checked: transparent;
      --checkmark-color: var(--ion-color-primary);
      --border-width: 1px;

      margin-inline-end: 10px;
      width: 25px;
      height: 25px;

      &.checkbox-checked {
        opacity: 1;
        --border-width: 2px;
      }
    }

    ion-label {
      word-break: break-word;
      white-space: unset;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &.item-checkbox-checked {
      --color: var(--ion-color-primary);
    }
  }

  &.checkbox-item.size-small {
    --min-height: 26px;
    --padding-end: 5px;
    --ion-safe-area-right: 0;
    --padding-start: 5px;

    ion-checkbox {
      width: 14px;
      height: 14px;
      --checkmark-width: 3px;
      margin-top: 0;
      margin-bottom: 0;

      margin-inline-end: 5px;
    }
    ion-label {
      font-size: 13px;
      margin-top: -2px;
      margin-bottom: 0;
      margin-right: 0;
    }

    ion-radio {
      opacity: 1;
      border-radius: 4px;
      --border-radius: 4px;
      width: 16px;
      height: 16px;
      margin-top: 0;
      margin-bottom: 0;
      border: 1px solid #cbcbce;
      margin-inline-end: 5px;
      margin-inline-start: 0;
      transition: var(--transition);
    }

    &.item-radio-checked {
      ion-radio {
        border-width: 2px;
        border-color: var(--ion-color-primary);
      }
    }
  }

  ion-datetime {
    --placeholder-color: #525456;
    padding-left: 10px;
    font-weight: 500;
  }

  &.item-has-value {
    ion-datetime,
    ion-select {
      color: var(--ion-color-dark);
      font-weight: 600;
    }
  }

  ion-select {
    --placeholder-opacity: 1;
    --placeholder-color: #525456;
    padding-left: 10px;
    font-weight: 500;
    max-width: 100%;
    width: 100%;
  }

  .hide {
    display: none;
  }
}

.phone {
  padding-left: 10px !important;
  color: #525456;
  font-weight: 500;
  opacity: 1;
  border: none;
  outline: none;
}

input[prefix^="+38"].ng-touched,
input[prefix*="+38"].ng-dirty,
input[prefix^="+38"].ng-valid {
  font-weight: 600 !important;
  color: var(--ion-color-dark) !important;
}

.error-message {
  color: var(--ion-color-danger);
  font-size: 11px;
  line-height: 1.6;
  padding-left: 10px;
  padding-top: 2px;
}
/***********************************************************
                        ELEMENTS - ITEMS
***********************************************************/

/* enter item */
.enter-item-row {
  ion-col {
    margin-bottom: 15px;
  }
}

.enter-item {
  padding-bottom: 104%;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(17.3deg, rgba(0, 0, 0, 0.8) 4.74%, rgba(0, 0, 0, 0) 52.32%);
    z-index: 1;
  }
  // ion-img
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .decor-title {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    padding-left: 17px;
    padding-right: 10px;
    padding-bottom: 5px;
    color: var(--ion-color-light);
    text-align: left;
    line-height: 1;
    z-index: 1;

    &:before {
      width: auto;
      right: 20px;
      left: 8px;
      background-image: url("assets/img/decor-line-small.svg");
      background-position: bottom left;
    }
  }
}
/* sale item */
.sale-item {
  &-image {
    position: relative;
    display: block;
    padding-bottom: 49%;
    // ion-img
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    padding: 10px 10px 25px;
  }

  &-title {
    margin-bottom: 10px;
  }
}
/***********************************************************
                        CATALOGUE NEW
***********************************************************/
.product {
  margin: 0 0 20px;
  border-radius: 5px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0);
  overflow: visible;
}

.product-img-col {
  // ion-img
  img {
    outline: none;
    margin-left: -7px;
    border-radius: 10px;
  }
}
.product-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 1px 0 10px;
  @media screen and (max-width: 350px) {
    padding: 0 1px 0 5px;
  }
}
.product-title {
  --padding-start: 0;
  font-size: 15px;
  line-height: 1.2;
  --min-height: 20px;
  margin-bottom: 5px;
  font-weight: 700;

  ion-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.product-weight {
  font-size: 13px;
  line-height: 1.4em;
  color: inherit;
}

.product-content {
  .product-descriptions {
    font-family: var(--ion-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    text-transform: lowercase;
    color: var(--ion-color-medium);
  }
}

.btn-to-favourite {
  --padding-start: 0;
  --padding-end: 0;
  margin: 0;
  background-image: url(assets/icon/icon-favourite-bg.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;

  &.ion-activated {
    opacity: 0.75;
  }

  ion-icon {
    width: 17px;
  }
}

.product-price {
  font-size: 16px;
  line-height: 17px;
  font-weight: 700;
  word-break: break-word;
  margin-right: 3px;

  &.old-price {
    position: relative;
    line-height: 1.2;
    vertical-align: middle;
    font-size: 13px;
    white-space: nowrap !important;
  }

  &:last-child {
    margin-right: 0px;
    margin-left: 1px;
  }
}

.product-segment {
  --background: var(--ion-color-light);
  &:before {
    content: "";
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
  }
  &:after {
    content: "";
    position: absolute;
    box-sizing: border-box;
    left: 2px;
    top: 2px;
    right: 2px;
    bottom: 2px;
    border: 1px solid #e8e8e8;
    border-radius: 30px;
  }
}

.product-segment-btn {
  margin: 0;
  box-sizing: border-box;
  z-index: 1;
  min-width: unset;
  font-size: 11px;
  height: 32px;
  --indicator-box-shadow: inset 0px 0px 100px 100px var(--ion-color-light-grey);
  --color: var(--ion-color-dark);
  --color-checked: var(--ion-color-dark);
  --border-radius: 30px;
  --padding-start: 5px;
  --padding-end: 5px;

  &:before {
    border: none;
  }

  &.segment-button-checked {
    font-weight: 700;
    font-size: 12px;
  }

  &.segment-button-no-checked {
    font-size: 11px !important;
    font-weight: normal !important;
  }

  ion-label {
    margin-top: 0;
  }
}

.product-highlight {
  position: absolute;
  top: 0;
  font-size: 11px;
  // text-transform: uppercase;
  font-weight: 700;
  padding: 6px 11px 6px 9px;
  pointer-events: none;
  overflow: hidden;
  // max-width: 75%;
  min-width: auto;

  &:before {
    content: "";
    position: absolute;
    left: -1px;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--ion-color-primary);
    transform-origin: left top;
    // transform: skewX(-15deg);
    border-radius: 0 20px 20px 0;
  }

  ion-text {
    position: relative;
  }
}

/* radio-group as segment */

.product-sides-wrap {
  margin-left: -12px !important;
  margin-right: -12px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  padding: 2px;
}

.radio-segment-group {
  display: flex;
  border-radius: 30px;
  border: 1px solid #e8e8e8;
  height: auto;
}

.radio-segment-item {
  --min-height: 26px;
  --border-color: transparent;
  --ion-safe-area-left: 0;
  --ion-safe-area-right: 0;
  --background: transparent;
  --inner-border-width: 0;

  font-size: 11px;
  flex-grow: 1;
  flex-shrink: 0;
  text-align: center;
  overflow: visible;

  &:before {
    content: "";
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    background-color: var(--ion-color-light-grey);
    border-radius: 30px;
    opacity: 0;
    transition: var(--transition);
  }

  &.item-radio-checked {
    font-weight: 700;

    &:before {
      opacity: 1;
    }
  }

  ion-label {
    margin: 0;
  }

  ion-radio {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 !important;

    &.radio-checked {
      & + ion-label {
      }
    }
  }
}

ion-radio-group > ion-item {
  --ripple-color: transparent;
}
/***********************************************************
                      PRODUCT DETAIL
************************************************************/
.add-item {
  app-counter > div {
    max-width: 100px;
    margin: 0 auto;
  }
}

.pos-arrow {
  width: 100%;
  width: -webkit-fill-available;
  padding: 0 5px;
  position: relative;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: 0;
  .arrow {
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    top: -25px;
    height: 40px;
    .img {
      height: 26px;
      width: 26px;
    }
  }
}

.product-detail {
  .product-weight {
    display: inline-block;
    margin-bottom: 5px;
  }

  &-title {
    margin-bottom: 10px;
  }

  &-description {
    margin-bottom: 15px;

    a {
      color: var(--ion-color-medium);
    }
  }

  &-btn {
    margin-bottom: 25px;
  }

  &-price {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 94px;
    height: 94px;
    background-image: url(assets/icon/decor-red.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;

    > b {
      margin-left: 6px;
      margin-top: 7px;
    }
  }

  .product-sides-wrap {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 767px) {
  .product-detail-info {
    margin-top: 10px;
  }
}
/* additions modal */
.additions-modal {
  ion-header {
    border-bottom: 1px solid var(--ion-color-tertiary);

    ion-title {
      padding-inline-end: 50px;
    }
  }

  ion-footer {
    border-top: 1px solid var(--ion-color-tertiary);

    ion-toolbar {
      padding-right: 10px;
    }

    ion-title {
      padding-inline-start: 20px;
      padding-inline-end: 110px;
    }

    .btn {
      font-weight: 700;
    }
  }
}
/***********************************************************
                      ORDER
************************************************************/
.alert-title.sc-ion-alert-md {
  font-size: 17px;
}
.alert-message.sc-ion-alert-md {
  font-size: 15px;
}

.location-alert {
  .alert-radio-group {
    max-height: inherit;
  }
}
/***********************************************************
                      POPOVER
************************************************************/
.information-popover {
  --max-width: 500px;
  --min-height: 160px;
  --width: calc(100% - 30px);
  --backdrop-opacity: 0.6;
  .popover-content {
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%)!important;
    padding: 0;
    border-radius: 5px;
    transform-origin: center center !important;
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.1);
  }
}

.select-popover {
  // Треба бо ХРОМ або ГУГЛ чомусь ламає відображення
  [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
  }
  
  .popover-content {
    min-width: unset !important;
    width: auto;
    overflow: scroll;
  }

  ion-item {
    --padding-end: 20px;

    &:last-child {
      --inner-border-width: 0;
    }
  }

  ion-label {
    white-space: normal !important;
  }

  &.filters-popover ion-label {
    white-space: normal !important;
    font-size: 13px !important;
    font-weight: 500;
    margin: 0;
  }

  &.busket-popover {
    ion-list {
      padding: 10px;
    }
    ion-item {
      --min-height: 24px;
      --border-color: transparent;
      --ion-safe-area-right: 0;
      --background: transparent;
      --inner-border-width: 0;
      font-size: 11px;
      flex-grow: 1;
      flex-shrink: 0;
      text-align: center;
      overflow: visible;
      margin-bottom: 10px;
      --border-color: #e8e8e8;
      --border-radius: 20px;
      --border-width: 1px;
      --border-style: solid;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: var(--ion-color-primary);
        border-radius: 30px;
        opacity: 0;
        transition: var(--transition);
      }

      &.item-radio-checked {
        --border-width: 0 !important;
        color: var(--ion-color-light);

        &:before {
          opacity: 1;
        }
      }

      &:last-child {
        margin: 0;
      }
    }

    ion-radio {
      width: 0;
      margin: 0;
      --color-checked: transparent;
    }

    ion-label {
      white-space: normal !important;
      font-size: 11px !important;
      font-weight: 500;
      margin: 0;
    }
  }
}
/***********************************************************
                      ALERT
************************************************************/
.alert-title {
  text-align: center;
  font-family: var(--ion-font-family);
}

.alert-message {
  text-align: center;
}
/***********************************************************
                      SKELETONS
************************************************************/
/* article skeleton */
.text-line-skeleton {
  height: 19px;
}

.square-skeleton {
  padding-bottom: 100%;
  margin-bottom: 0;
}

.square-img-skeleton {
  padding-bottom: 74%;
}

.description-skeleton {
  height: 50px;
  margin-bottom: 25px;

  &.type-2 {
    height: 30px;
  }
}

.addition-skeleton {
  width: 90%;
  height: 20px;
  margin: 10px auto 15px;
}

.half-square-skeleton {
  padding-bottom: 49%;
}

.button-skeleton {
  width: 200px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}

.address-title-skeleton {
  margin-left: 10px;
  width: 100px;
  height: 16px;
}

.input-order-field-skeleton {
  margin: 10px 0 0;
  height: 40px;
  width: 100%;
}

.grn {
  font-size: 12px;
  text-transform: lowercase;
  margin-left: 3px;
  white-space: nowrap !important;
}
// delivery map modal statusbar fix
.ios .ios-modal-safe-top-offset {
  top: var(--ion-safe-area-top);
}

.ios .ios-modal-safe-top-offset ion-toolbar {
  padding-top: 0;
}

/* from file - style scss */

/*spaces*/
.bs-xs-15 {
  height: 15px;
}
.bs-xs-20 {
  height: 20px;
}
.bs-xs-30 {
  height: 30px;
}

/*fonts*/
.size-30 {
  font-size: 30px;
}

/*card*/
.card {
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  border-radius: 5px;
  outline: none;
}

.icon-text {
  font-size: 15px;
  line-height: 1.2;

  b {
    vertical-align: middle;
  }

  ion-icon {
    width: 24px;
    height: 20px;
    vertical-align: middle;
    margin-left: 2px;

    &.size-s {
      height: 16px;
      width: 16px;
      margin-top: -2px;
    }
  }
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
/* order-bottom */
.order-bottom {
  margin-top: 20px;
  padding: 20px 15px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  .btn {
    margin-top: 20px;
  }

  .pos-arrow {
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;

    .arrow {
      left: 0;
      top: 0;
    }
  }
}

.order-bottom-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;

  .icon-text {
    flex-shrink: 0;
    padding-left: 10px;
  }
}
/* end of order-bottom */

/*flex*/
.left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* custom toast */
.ios .custome-toast {
  margin-bottom: var(--ion-safe-area-bottom) !important;
}

.ios .custome-toast.active {
  transform: translateY(-56px);
}

/***********************************************************
                          Slider Pagination
***********************************************************/
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 15px 0 0;
  &:last-child {
    margin: 0;
  }
}
.swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  display: inline-block;
  border-radius: 50%;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: 0.39s ease-in;
}
.swiper-pagination-bullet-active {
  background: transparent;
  border: 2px solid var(--ion-color-primary);
}

.ios .main-router-outlet {
  background: var(--ion-color-dark);
}

// phone input item fix
.phone-input-item {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 45px;
    z-index: 2;
  }
}
// end of phone input item fix

// Щоб на ios працював disabled !!!
ion-button:not(.header-phone)::part(native) {
  pointer-events: none;
}
